@use '../functions' as *;
@use 'styles/qdon/variables' as *;

@use '../variables' with (
  $ui-base-color: $grey,
  $ui-primary-color: $skyblue,
  $ui-secondary-color: $pink,
  $primary-text-color: #fff,
  $secondary-text-color: #d9e1e8,
  $ui-highlight-color: $dark-pink,
  $ui-button-background-color: $dark-blue,
  $ui-button-focus-background-color: darken($dark-blue, 15%),
  $ui-button-tertiary-color: $dark-blue,
  $ui-button-tertiary-border-color: $dark-blue,
  $ui-button-tertiary-focus-background-color: darken($dark-blue, 15%),
  $font-sans-serif: (
    'mastodon-font-sans-serif',
    'qdon-font-sans-serif',
  )
);
